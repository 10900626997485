<template>
	<div class="about-card">
		<div class="about-card__hdng">{{ content.name }}</div>
		<div class="about-card__text">
			{{ content.text }}
		</div>
		<img
			v-lazy="content.img"
			:alt="content.name"
      :width="content.width"
      :height="content.height"
			class="about-card__img about-card-img"
		/>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	}
}
</script>

<style lang="sass">
.about-card
  display: flex
  flex-direction: column
  align-items: center
  max-width: 266px
  min-width: 266px
  padding: 40px 20px 0
  height: 360px
  text-align: center
  border-radius: 10px
  background-color: #FBFBFB
  transition: 1s
  overflow: hidden
  box-sizing: border-box

  &__hdng
    margin-bottom: 15px
    font-size: 20px
    line-height: 21px
    font-weight: 700

  &__text
    max-width: 200px
    margin-bottom: auto
    width: 100%
    font-size: 12px
    line-height: 17px
    color: rgba(0, 0, 0, 0.4)

  &__img
    width: 100%
    transition: 0.5s

  &:hover
    &:nth-child(2n)
      .about-card-img
        transform: translateX(40px) translateY(50px) scale(1.2)

    &:nth-child(2n-1)
      .about-card-img
        transform: translateX(-40px) translateY(50px) scale(1.3)
</style>
